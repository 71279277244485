<template>
  <el-drawer
    custom-class="drawerIconNone"
    v-model="drawerVisible"
    :close-on-click-modal="false"
    title="印章审核详情"
    direction="rtl"
    size="500px"
  >
    <div class="detailsbox">
      <div class="ovbox">
        <img
          class="w-160 h-160 mb-28"
          v-if="state.details.sealUrl"
          :src="state.details.sealUrl"
        />
        <img
          class="w-160 h-160 mb-28"
          v-else
          src="@/assets/img/general/sign.png"
        />
        <div class="labelbox dp-f">
          <div class="label">印章名称:</div>
          {{ state.details.sealName || "" }}
        </div>
        <div class="labelbox dp-f">
          <div class="label">印章类型:</div>
          {{ getsealtype(state.details.sealType || 0) }}
        </div>
        <!-- <div class="labelbox dp-f">
                    <div class="label">印章分类:</div>业务章
                </div> -->
        <div class="labelbox dp-f">
          <div class="label">创建人:</div>
          {{ state.details.userName || "" }}
        </div>
        <div class="labelbox dp-f">
          <div class="label">账号:</div>
          {{ state.details.phone || "" }}
        </div>
        <div class="labelbox dp-f">
          <div class="label">创建部门:</div>
          {{ state.details.deptName || "" }}
        </div>
        <div class="labelbox dp-f">
          <div class="label">印章编号:</div>
          {{ state.details.sealNo || "" }}
        </div>
        <div class="labelbox dp-f">
          <div class="label">创建时间:</div>
          {{ state.details.createDate || "" }}
        </div>
        <div class="labelbox dp-f">
          <div class="label">备注:</div>
          {{ state.details.remarks || "" }}
        </div>
        <!-- <div class="dp-f jc-sb ai-fe mt-50 mb-18">
                    <div class="fs-18">审核未通过</div>
                </div>
                <div class="labelbox dp-f">
                    <div class="label">原因:</div>这个章不适合签署合同
                </div> -->
      </div>
      <div class="fotbox dp-f jc-fe ai-c">
        <oabutton
          class="searcML"
          width="100"
          height="40"
          title="取消"
          CSStype="3"
          @buttonclick="drawerVisible = false"
        ></oabutton>
        <oabutton
          class="searcML"
          width="100"
          height="40"
          title="拒绝"
          CSStype="3"
          @buttonclick="unref(refuseRef).getData(state.details.id)"
        ></oabutton>
        <oabutton
          class="searcML mr-12"
          width="100"
          height="40"
          title="审核通过"
          CSStype="2"
          @buttonclick="submit()"
          v-model:loading="dialogloading"
        ></oabutton>
      </div>
    </div>
  </el-drawer>
  <refuse ref="refuseRef" @Emit="close"></refuse>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits } from "vue";
import { getcontractStatus, getsealtype } from "@/utils/server/getcode.js";
import refuse from "./refuse.vue"; //拒绝原因弹框
import { httpToken } from "@/utils/request";
import qs from "qs";
import { handleMessage } from "@/utils/server/confirm";
const dialogloading = ref(false); // 弹框按钮loading 显示
const dialogVisible = ref(true); //弹框显示
const refuseRef = ref(); //拒绝原因弹框ref
const emit = defineEmits(["agree"]);
const id = ref(0); //操作的id
const drawerVisible = ref(false); //抽屉显示
const state = reactive({
  selectionCount: 0, //选中数量
  data: {},
  details: {},
});
const getDetails = (data) => {
  drawerVisible.value = true;
  state.data = data;
  getDetailsapi(data.id);
};
const getDetailsapi = (id) => {
  //   loading.value = true;
  let json = [{ column: "id", type: "eq", value: id }];
  httpToken({
    method: "post",
    url: "/seal/seal/find",
    data: qs.stringify({
      paramData: JSON.stringify(json),
    }),
  })
    .then((res) => {
      state.details = res.data;
    })
    .catch((e) => {});
};
// 数据提交
const submit = () => {
  httpToken({
    method: "post",
    url: "/seal/seal-admin/examine",
    data: qs.stringify({ id: state.details.id, type: 1 }),
  }).then((res) => {
    drawerVisible.value = false;
    handleMessage("审核完成", "success");
    emit("agree");
  });
};
const close = () => {
  drawerVisible.value = false;
  emit("agree");
};
//       httpToken({
//     method: "post",
//     url: "/seal/seal-admin/examine",
//     data:qs.stringify({id:state.id,type:2}) ,
//   }).then(res=>{
//         dialogVisible.value = false
//           dialogloading.value=false
//         handleMessage('填写完成','sucess')
//   }).catch(e=>{
//         dialogloading.value=false
//   })
defineExpose({
  getDetails,
});
</script>
<style lang="scss" scoped >
.detailsbox {
  width: 100%;
  height: 100%;
  position: relative;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  padding: 0 34px;
  padding-top: 34px;
  .ovbox {
    height: calc(100% - 60px);
    overflow: auto;
  }
  .labelbox {
    margin-bottom: 16px;

    .label {
      flex-shrink: 0;
      width: 120px;
      color: #999999;
    }

    .status {
      color: #0083ff;
    }

    .status3,
    .status4 {
      color: #999999;
    }
  }

  .fotbox {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: #ffffff;
    box-shadow: 0px -1px 8px 0px rgba(219, 219, 219, 0.5);
  }
  // 隐藏滚动条
  ::-webkit-scrollbar {
    width: 0 !important;
  }
}
</style>